.App {
  text-align: left;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    #animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family:  Roboto, sans-serif;
  font-stretch: 100%;
  color: #234050;
  margin: 0 auto;
}

.App-header h2 {
    font-family:  brandonBlack;
    text-align: left;
}

.content {
    font-family:  Roboto, sans-serif;
    background-color: #385C6B;
    color: white;
    padding: 5px;
    padding-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
